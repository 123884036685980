import { graphql } from 'gatsby';
import React from 'react';

import { ProjectDetail } from '../components/Projects/ProjectDetail';
import { Root } from '../components/Root';
import { ProjectType } from '../types';
import { inBrowser } from '../utils/inBrowser';

export const query = graphql`
  query project($Slug: String!) {
    project: sanityProject(Slug: { eq: $Slug }) {
      Year
      Name
      Tags
      Client
      VideoUrl
      Slug
      Thumb {
        asset {
          fluid(maxWidth: 1200) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
    projects: allSanityProject(
      limit: 2
      sort: { fields: [_updatedAt], order: DESC }
      filter: { Slug: { ne: $Slug } }
    ) {
      edges {
        node {
          Client
          Slug
          Thumb {
            asset {
              fluid(maxWidth: 1200) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  }
`;

const project = ({ data }) => {
  const project: ProjectType = data.project;
  const projects: ProjectType[] = data.projects.edges.map(item => {
    return item.node;
  });

  let autoPlay = false;

  if (inBrowser()) {
    JSON.parse(localStorage.getItem(project.Slug)!);
    localStorage.removeItem(project.Slug);
  }

  return (
    <Root>
      <ProjectDetail
        project={project}
        projects={projects}
        autoPlay={autoPlay ? true : false}
      />
    </Root>
  );
};

export default project;
