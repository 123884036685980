import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import Vimeo from '@u-wave/react-vimeo';
import React from 'react';

import { Text } from '../../components/UI/Text';
import { Breakpoint, ProjectType } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { BackgroundLines } from '../BackgroundLines';
import { ProjectList } from '../Home/ProjectList';

type Props = {
  project: ProjectType;
  projects: ProjectType[];
  autoPlay?: boolean;
};

export function ProjectDetail({ project, projects, autoPlay = false }: Props) {
  const lastOfIndex = project.Tags.length - 1;

  const isVimeo = project.VideoUrl.includes('vimeo');
  const isYoutube = project.VideoUrl.includes('youtube');

  const youtubeUrlId = isYoutube
    ? `https://youtube.com/embed/${project.VideoUrl.split('v=')[1]}?autoplay=1`
    : null;

  return (
    <>
      <StyledContainer>
        {isVimeo && (
          <StyledVimeo
            video={`${project.VideoUrl}`}
            autoplay={autoPlay}
            responsive
          />
        )}
        {isYoutube && (
          <StyledYoutube
            src={youtubeUrlId as string}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            frameBorder={0}
          />
        )}
        <StyledClientContainer>
          <Client mode="subtitle">{project.Client}</Client>
          <ClientDescriptionContainer>
            <Description mode="buttonSecondary">{project.Year}</Description>
            {project.Tags.map((tag, index) => {
              return lastOfIndex === index ? (
                <Tag last={true} key={index} mode="gray">
                  {tag}.
                </Tag>
              ) : (
                <Tag last={false} key={index} mode="gray">
                  {tag},
                </Tag>
              );
            })}
          </ClientDescriptionContainer>
        </StyledClientContainer>
      </StyledContainer>
      <BottomBorder />
      <ProjectList title={'More projects'} projects={projects} />
      <BackgroundLines />
    </>
  );
}

const StyledContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      padding-top: 3.5rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    `
  )}

  ${forBreakpoint(
    Breakpoint.Desktop,
    css`
      padding-top: 3rem;
    `
  )}

${forBreakpoint(
  Breakpoint.UltraWide,
  css`
    padding-top: 7rem;
  `
)}
`;

const StyledYoutube = styled.iframe`
  position: relative;
  z-index: 5;
  width: 80vw;
  margin-top: 10vh;
  aspect-ratio: 16 / 9;

  ${forBreakpoint(
    Breakpoint.Phone,
    css`
      width: 90vw;
      display: block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    `
  )};

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      width: 70vw;
      margin-top: 6.5vh;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    `
  )};

  ${forBreakpoint(
    Breakpoint.Desktop,
    css`
      width: 60vw;
    `
  )};
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      width: 65vw;
    `
  )};
`;

const StyledVimeo = styled(Vimeo)`
  position: relative;
  z-index: 5;
  width: 80vw;
  margin-top: 10vh;

  ${forBreakpoint(
    Breakpoint.Phone,
    css`
      width: 90vw;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    `
  )};

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      width: 70vw;
      margin-top: 6.5vh;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    `
  )};

  ${forBreakpoint(
    Breakpoint.Desktop,
    css`
      width: 60vw;
    `
  )};
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      width: 65vw;
    `
  )};
`;

const StyledClientContainer = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
      margin-right: 2rem;
      margin-top: 2rem;
    `
  )};
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin-top: 7rem;
      margin-right: 45rem;
    `
  )};
`;

const ClientDescriptionContainer = styled.div`
  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      max-width: 500px;
      text-align: right;
    `
  )};
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      max-width: 1500px;
    `
  )};
`;

const Client = styled(Text)`
  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      margin-left: 2rem;
    `
  )};
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin-left: 6rem;
    `
  )};
`;

const Description = styled(Text)`
  display: block;
  margin-top: 0.5rem;
  font-size: 1.2rem;

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      margin-top: 0;
      text-align: right;
    `
  )};

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin-bottom: 1rem;
      font-size: 3rem;
    `
  )};
`;

const Tag = styled(Text)<{ last: boolean }>`
  font-size: 1rem;
  margin-right: ${props => (props.last ? 0 : '5px')};
`;

const BottomBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
`;
